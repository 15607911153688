import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = true;

export const hardCodeTime = true
  ? {
      registration_time: '2022-09-09T10:00:00Z',
      open_time: '2022-09-12T10:00:00Z',
      fcfs_round_time: '2022-09-12T23:00:00Z',
      close_time: '2022-09-13T11:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Moon Metaverse IDO',
  alias: projectAlias.moonMetaverse,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdK_-kdBdIkbLu6EZ02gRY_EYltic_NsQ4KLmAUGUJ8WYW7fg/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.moonMetaverse}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/moon/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaIDO/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaIDO/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/moon/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaIDO/text-logo.png`,
  inventoryLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaIDO/big-logo.png`,
  // url: `/${projectAlias.moonMetaverse}`,
  saleType: '',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: '',
  meta: [
    {
      label: 'Price',
      value: 'TBA',
      prefix: '$',
    },
  ],
  starts: 'TBA',
  ends: 'Sep 14 2022, 03:00 AM UTC',
  VIPRound: 'Sep 12 2022, 10:00 AM UTC',
  TripleRound: 'Sep 12 2022, 10:00 PM UTC',
  fcfsRound: 'Sep 12 2022, 11:00 PM UTC',
  apiDown: true,
  enable: false,
  status: projectStatuses.upcoming,
  tokenName: '$OKG',
  tokenSymbol: '$OKG',
  totalSupply: '500000000',
  initialSupply: '5400000',
  initialMarketCap: '270000',
  tokenListing: 'Sep 14 2022',
  registration_open_time: 'TBA',
  registration_close_time: 'Until Sep 11 2022, 10:00 AM UTC',
  allocation_round_time: 'Sep 12 2022, 11:00 AM UTC',
};
