import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-04-20T13:00:00Z',
      double_limit_round_time: '2022-04-21T13:00:00Z',
      fcfs_round_time: '2022-04-21T14:00:00Z',
      close_time: '2022-04-22T14:00:00Z',
    }
  : null;
export const projectData = {
  name: 'Ookeenga',
  alias: projectAlias.ookeengaINO,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLScTlU_1fvbDxksFXWDI05mlpT2Ztvknr_Lz-xxIQalryzwo-A/viewform?usp=send_form',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.ookeengaINO}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeengaINO}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeengaINO}/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeengaINO}/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeengaINO}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeengaINO}/text-logo.png`,
  url: `/${projectAlias.ookeengaINO}`,
  saleType: 'INO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 30000,
  meta: [
    {
      label: 'Quantity',
      value: 600,
      prefix: '',
    },
  ],
  starts: 'April 20, 13:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: 'Ookeenga',
  tokenSymbol: 'OKG',
  totalSupply: 500000000,
  initialSupply: 7200000,
  initialMarketCap: 360000,
  tokenListing: 'TBA',
  registration_open_time: '2022-03-23T13:00:00Z',
  registration_close_time: '2022-04-07T13:00:00Z',
  allocation_round_time: '2022-04-20T13:00:00Z',
};
