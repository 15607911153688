import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = false
  ? {
    registration_time: '2022-08-25T03:00:00Z',
      open_time: '2022-08-25T10:00:00Z',
      fcfs_round_time: '2022-08-26T11:00:00Z',
      close_time: '2022-08-26T23:00:00Z',
    }
  : null;

export const projectData = {
  name: 'SPUME IDO',
  alias: projectAlias.spume,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSeelPfTlhiO_B6Om8e-avaMV_Nyykwi59qROwF7TIFJncCZOg/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.spume}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/spume/banner.jpg`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/spume/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/spume/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/spume/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/spume/text-logo.png`,
  url: `/${projectAlias.spume}`,
  saleType: '',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 25000,
  meta: [
    {
      label: 'Price',
      value: 0.4,
      prefix: '$',
    },
  ],
  starts: 'August 25, 10:00 UTC',
  apiDown: false,
  enable: true,
  status: projectStatuses.complete,
  saleStatus: saleStatuses.soldOut,
  tokenName: '$SPUME',
  tokenSymbol: '$SPUME',
  totalSupply: '100000000',
  initialSupply: '2378000',
  initialMarketCap: '1189000',
  tokenListing: '29 August, 2022',
  registration_open_time: 'TBA',
  registration_close_time: '2022-08-25T03:00:00Z',
  allocation_round_time: '2022-08-25T10:00:00Z',
};
