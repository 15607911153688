import { projectAlias } from 'data';
import { projectStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const projectData = {
  name: 'Ritestream',
  alias: projectAlias.ritestream,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSexOK7VhfMriJFDWu-jo8DitLl63-o-6gHGHpVrR7t2nCOgxA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.ritestream}`,
  addressReceiver: '',

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ritestream}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ritestream}/big-banner.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ritestream}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ritestream}/text-logo.png`,
  url: `/${projectAlias.ritestream}`,
  saleType: 'IDO',
  // saleStatus: 'Sold out',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Price',
      value: 0.04,
      prefix: '$',
    },
  ],
  starts: 'March 08, 03:00 UTC',
  enable: false,
  status: projectStatuses.completed,
};
