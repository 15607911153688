import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-04-25T13:00:00Z',
      fcfs_round_time: '2022-04-26T14:00:00Z',
      close_time: '2022-04-27T14:00:00Z',
    }
  : null;

export const projectData = {
  name: 'MetaSpets',
  alias: projectAlias.metaSpets,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSearg3kZfAlUshyIkp2NNrXloxWzuQNyarQAEaVKG_bgVqpJA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.metaSpets}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/metaSpets/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/metaSpets/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/metaSpets/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/metaSpets/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/metaSpets/text-logo.png`,
  url: `/${projectAlias.metaSpets}`,
  saleType: 'Private Sale',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Price',
      value: 0.025,
      prefix: '$',
    },
  ],
  starts: 'April 25, 13:00 UTC',
  apiDown: false,
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: 'MetaSpets',
  tokenSymbol: 'MSP',
  totalSupply: '500000000',
  initialSupply: '4400000',
  initialMarketCap: '198000',
  tokenListing: '29 April 2022 14:00 (UTC)',
  registration_open_time: 'TBA',
  registration_close_time: '2022-04-15T13:00:00Z',
  allocation_round_time: '2022-04-16T13:00:00Z',
};
