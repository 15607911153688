import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
    registration_time: '2022-05-30T10:00:00Z',
      open_time: '2022-05-31T10:00:00Z',
      fcfs_round_time: '2022-05-31T16:00:00Z',
      close_time: '2022-06-01T11:16:00Z',
    }
  : null;

export const projectData = {
  name: 'Get Kicks IDO',
  alias: projectAlias.getKicks,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSearg3kZfAlUshyIkp2NNrXloxWzuQNyarQAEaVKG_bgVqpJA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.getKicks}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/getkicks/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/getkicks/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/getkicks/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/getkicks/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/getkicks/text-logo.png`,
  url: `/${projectAlias.getKicks}`,
  saleType: '',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 15000,
  meta: [
    {
      label: 'Price',
      value: 0.005,
      prefix: '$',
    },
  ],
  starts: 'May 31, 10:00 UTC',
  apiDown: false,
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: '$KICK',
  tokenSymbol: '$KICK',
  totalSupply: '5000000000',
  initialSupply: '43750000',
  initialMarketCap: '218750',
  tokenListing: 'TBA',
  registration_open_time: 'TBA',
  registration_close_time: '2022-05-30T10:00:00Z',
  allocation_round_time: '2022-04-16T13:00:00Z',
};
