import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = false
  ? {
      registration_time: '2022-09-09T10:00:00Z',
      open_time: '2022-10-18T10:00:00Z',
      fcfs_round_time: '2022-10-19T11:00:00Z',
      close_time: '2022-10-20T11:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Galaxy Arena IDO',
  alias: projectAlias.galaxyArena,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfw7WTRPvunoVcBbYY_59OXHCXGuZVaVUju0hiwTFH4TqeTSA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.galaxyArena}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,
  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/text-logo.png`,
  inventoryLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/galaxyArena/big-logo.png`,
  url: `/${projectAlias.galaxyArena}`,
  saleType: '',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 25000,
  meta: [
    {
      label: 'Price',
      value: 0.03,
      prefix: '$',
    },
  ],
  starts: 'Oct 18 2022, 10:00 AM UTC',
  ends: 'Oct 20 2022, 11:00 AM UTC',
  VIPRound: 'Oct 18 2022, 10:00 AM UTC',
  TripleRound: 'Oct 19 2022, 10:00 AM UTC',
  fcfsRound: 'Oct 19 2022, 11:00 AM UTC',
  apiDown: false,
  enable: true,
  status: projectStatuses.upcoming,
  // saleStatus: saleStatuses.soldOut,
  tokenName: 'ESNC',
  tokenSymbol: 'ESNC',
  totalSupply: '1000000000',
  initialSupply: '11300000',
  initialMarketCap: '395500',
  tokenListing: 'Oct 21 2022',
  registration_open_time: 'TBA',
  registration_close_time: 'Oct 17 2022, 10:00 AM UTC',
  allocation_round_time: 'Sep 12 2022, 11:00 AM UTC',
};
