import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-04-13T13:00:00Z',
      double_limit_round_time: '2022-04-14T13:00:00Z',
      fcfs_round_time: '2022-04-14T14:00:00Z',
      close_time: '2022-04-15T02:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Realms of Ethernity',
  alias: projectAlias.roe,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLScqiJqtaGzjouObQ4kHtk8gaJMXURfx-fgcbAr8eMmyWy_-zQ/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.roe}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.roe}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.roe}/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.roe}/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.roe}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.roe}/text-logo.png`,
  url: `/${projectAlias.roe}`,
  saleType: 'IDO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Price',
      value: 0.03,
      prefix: '$',
    },
  ],
  starts: 'April 13, 13:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: 'Realms of Ethernity',
  tokenSymbol: 'RETH',
  totalSupply: '1,378,000,000',
  initialSupply: '17,500,000',
  initialMarketCap: '$525,000',
  tokenListing: 'TBA',
  registration_open_time: 'TBA',
  registration_close_time: '2022-04-13T13:00:00Z',
  allocation_round_time: '2022-04-12T13:00:00Z',
};
