import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-03-25T13:00:00Z',
      double_limit_round_time: '2022-03-26T13:00:00Z',
      fcfs_round_time: '2022-03-26T14:00:00Z',
      close_time: '2022-03-26T16:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Animal Concerts',
  alias: projectAlias.animalconcertsFS,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfh6HvuJP91q9IKvMD3tcb-sWdIxUsVvrEPcyr895RsC2IlLQ/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.animalconcertsFS}`,
  addressReceiver: process.env.REACT_APP_ANIMALCONCERTS_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.animalconcertsFS}/banner.jpg`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.animalconcertsFS}/big-banner.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.animalconcertsFS}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.animalconcertsFS}/text-logo.png`,
  url: `/${projectAlias.animalconcertsFS}`,
  saleType: 'IDO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 15000,
  meta: [
    {
      label: 'Price',
      value: 0.0045,
      prefix: '$',
    },
  ],
  starts: 'March 28, 12:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
};
