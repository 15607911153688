import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = false
  ? {
    registration_time: '2022-09-09T10:00:00Z',
    open_time: '2022-09-12T10:00:00Z',
    fcfs_round_time: '2022-09-12T23:00:00Z',
    close_time: '2022-09-13T11:00:00Z',
  }
  : null;

export const projectData = {
  name: 'Ookeenga INO Public Pool',
  alias: projectAlias.ookeengaINO2,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdK_-kdBdIkbLu6EZ02gRY_EYltic_NsQ4KLmAUGUJ8WYW7fg/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.ookeengaINO2}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/text-logo.png`,
  inventoryLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/ookeengaINO2/big-logo.png`,
  url: `/${projectAlias.ookeengaINO2}`,
  saleType: '',
  acceptedTokens: ['BUSD', 'USDT', 'SPO'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Quantity',
      value: 500,
      prefix: ''
    },
    {
      label: 'Pool info',
      value: 'Open for EVERYONE - FCFS',
      prefix: '',
    },
  ],
  starts: '12 October 2022 10:00 (UTC)',
  ends: '18 October 2022 10:00 (UTC) ',
  apiDown: false,
  enable: true,
  status: projectStatuses.upcoming,
  // saleStatus: saleStatuses.soldOut,
  tokenName: '$OKG',
  tokenSymbol: '$OKG',
  totalSupply: '500000000',
  initialSupply: '5400000',
  initialMarketCap: '270000',
  tokenListing: 'Sep 14 2022',
  registration_open_time: 'TBA',
  registration_close_time: 'Until Sep 11 2022, 10:00 AM UTC',
  allocation_round_time: 'Sep 12 2022, 11:00 AM UTC',
};
